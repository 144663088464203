.main .acknowledgement{
    margin: 5vh;
}

.main .acknowledgement a{
    padding: 0 2vw;
    height: 30vh;
}

.main .acknowledgement img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}