@import '../../references/index';

.project-input {
  display: flex;
  flex-direction: column;

  label {
    color: $grayLight;
    font-size: 16px;
    margin-bottom: 6px;
    font-weight: 600;
    direction: rtl;
    text-align: right;
  }

  input {
    height: 44px;
    padding: 0 18px;
    background-color: $grayLight;
    outline: none;
    font-size: 16px;
    color: $black;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    direction: rtl;
    min-width: 220px;

    ::placeholder {
      color: red;
      font-size: 12px;
    }


    &:disabled {
      background-color: $grayLight;
      cursor: not-allowed;
      opacity: 0.5;
    }

  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  > p {
    font-size: 12px;
    color: $red;
    max-height: 0px;
    opacity: 0;
    transition: 0.3s;
    overflow: hidden;

    &.active {
      max-height: 18px;
      opacity: 1;
    }
  }
}
