* {
    --primary-color: #1890ff;/*rgb(87, 151, 230);*/
    --secondary-color: rgb(243, 71, 85);
    --tertiary-color: #bebebe;
    --font-color: #221e24;
    --font-color2: #56485e;
    --font-color3: rgb(168, 160, 167);
    --bg-color: rgb(255, 255, 255);
    --heading-color: #161c35;
    --bg-color2: rgb(233, 229, 233);
    --bg-color2-2: rgb(235, 235, 235);
    --bg-color3: rgb(182, 172, 180);
    --bg-color4: var(--bs-gray-100);
    --footer-bg: #2b272e;
}