@import "../../../utilities/references/index";

.back-istj{
    background-color: $brownLight;
    display: flex;
    width: 100%;
    position: absolute;
    text-align: justify;
    

    @include project-md{
        height: 100%;
    }

    .card{
        display: flex;
        width: 100%;
        margin-left: 5%;
        margin-right: 5%;
        background-color: $white;
        opacity: 90%;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;    
        border-radius: 15px;
        padding: 20px;
        margin-top: 15px;
        margin-bottom: 15px;
        
        .content{
            display: flex;
            //position: relative;
            width: 100%;
            height: 100%;
            //padding: 20px;
            // margin-top: 15px;
            // margin-bottom: 15px;
            flex-direction: column;
            

            @include project-md {
                flex-direction: row;
            }

            .typeimg{
                display: flex;
                width: 100%;
                height: 40%;
                margin-left: auto;
                margin-right: auto;
                // background: url(../../../assets/images/types/istjj/ISTJ.jpg);
                // background-size: contain;
                // background-repeat: no-repeat;

                @include project-md{
                    display: flex;
                    height: 100%;
                    margin-top: auto;
                    margin-bottom: auto;
                    width: 80%;

                
                }

                .responsive{
                    width: 100%;
                    height:auto;
                }
            }

            .scroll {
                overflow-y:scroll;
            }

            // ::-webkit-scrollbar {
            //   }
            
            .info{
                display: flex;
                width: 100%;
                height: 100%;
                direction: rtl;
                flex-direction: column;
                font-size: large;
                padding: 20px;
                border-color: $brownLight;
                .content-button{
                    display: flex;
                    width: 50%;
                    margin-top: 5%;
                    margin-right: 25%;
                    margin-left: 25%;
                    background-color: $blueDark;
                    border: none;
                    font-weight: bold;
                    border-radius: 5px;
                    color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    
                }

                border: solid;
                border-width:2px ;

                @include project-md{
                    border: solid;
                    border-width:2px ;
                    border-color: $brownLight;
                    margin-top: 0px;

                    // background: none;

                }
                
                .title{
                    font-size: 25px;
                    font-weight: bold;
                    border-style: inherit;
                    border-color: $brownLight;
                    padding-right: 10px;
                    padding-left: 10px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    border-width: 2px;
                    border-radius: 10px;
                }

                .explain{
                    b{
                        font-weight: 700;
                    }

                }

                .jobstitle{
                    font-size: 20px;
                    font-weight: 500;
                    background-color: $brownLight;
                    padding-right: 10px;
                    padding-left: 10px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    border-radius: 10px;
                    margin-top: 10px;
                    color: white;
                    
                }

                .jobs{

                }

                .Propertytitle{
                    font-size: 20px;
                    font-weight: 500;
                    background-color: $brownLight;
                    padding-right: 10px;
                    padding-left: 10px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    border-radius: 10px;
                    margin-top: 10px;
                    color: white;
                }

                .Property{

                }

                .personstitle{
                    font-size: 20px;
                    font-weight: 500;
                    background-color: $brownLight;
                    padding-right: 10px;
                    padding-left: 10px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    border-radius: 10px;
                    margin-top: 10px;
                    color: white;
                }


                .persons{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;

                    @include project-md{
                        flex-direction: row;
                    }

                    .item{
                        display: flex;
                        flex-direction: column;
                        // margin-left: auto;
                        // margin-right: auto;
                        
                        .item-detail{
                            display: flex;
                            margin-top: 15px;

                            img{
                                height: 70px;
                                width: 70px;
                                border-radius: 8px;
                            }

                            .intru{
                                display: flex;
                                margin-right: 10px;
                                //font-size: large;
                                flex-direction: column;
                                padding-left: 20px;

                                .name{
                                    font-weight: 500;
                                    text-align: initial;
                                }

                                .role{
                                    font-size: 13px;
                                    background-color: $grayWhite;
                                    font-weight: 400;
                                    padding: 7px;
                                    width: fit-content;
                                    border-radius: 25px;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
