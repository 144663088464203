
@import "theme/main.scss";

.dataset-guideline{
    text-align: center;
    h1{

        background-color: color(primary-color);
        padding:80px;
    }
    .guide{
        margin-top: 50px;
        font-size: large;
        display: inline-block;
        max-width: 1000px;
        text-align: right;
        justify-content: center;
        li{
            padding: 20px 0px;
        }
        ul li{
            padding: 5px 0;
        }
        ul .desc{
            font-size: 1rem;
        }
        ul, ol{
            margin-right: 2rem;
        }
    }
    img{
        width: 80%;
        display: block;
        margin: 0 auto;
    }
    .correct{
        color:mediumseagreen;
        // margin: 3px 0;
    }
    .incorrect{
        color: red;
        // margin: 3px 0;
    }
}