.PVQA_App {
  text-align: center;
  background-color: var(--bg-color);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-holder{
  margin-top: 56px;
  background-color: var(--bg-color);
  min-height: calc( 100vh - 56px - 4rem);
  position: relative;
  color: var(--font-color);
}
/* 
@media(max-width:547px){
  .page-holder{
    margin-top: 80px;
  }
} */