/* [data-theme="l"] {
    --primary-color: #b96a9d;
    --secondary-color: #594F99;
    --third-color: #fbb3ae;
    --font-color: #221e24;
    --font-color2: #56485e;
    --font-always-light-color: #fff;
    --font-always-dark-color: #000;
    --bg-color: #fff;
    --heading-color: #945798;
    --bg-color2: rgb(233, 229, 233);
    --bg-color2-2: rgb(243, 240, 243);
    --bg-color3: rgb(222, 214, 230);
}


[data-theme="d"] {
    --primary-color: #b96a9d;
    --secondary-color: #594F99;
    --third-color: #915753;
    --font-color: #e1e1ff;
    --font-color2: #dbd6e2;
    --font-always-light-color: #fff;
    --font-always-dark-color: #000;
    --bg-color: #161625;
    --bg-color2: #3b335e;
    --bg-color2-2: #292341;
    --heading-color: rgb(178, 118, 184);
    --bg-color3: #3a2b57;
} */

/* [data-theme="l"] {
    --primary-color: #29c0b8;
    --secondary-color: #ff0080;
    --tertiary-color: #a1fff7;
    --font-color: #221e24;
    --font-color2: #56485e;
    --bg-color: rgb(255, 255, 255);
    --heading-color: #257972;
    --bg-color2: rgb(233, 229, 233);
    --bg-color2-2: rgb(235, 235, 235);
    --bg-color3: rgb(182, 172, 180);
}


[data-theme="d"] {
    --primary-color: #0bb3aa;
    --secondary-color: #ff0080;
    --tertiary-color: #5f9691;
    --font-color: #e1e1ff;
    --font-color2: #cabdc6;
    --bg-color: #161625;
    --bg-color2: #3b335e;
    --bg-color2-2: #292341;
    --heading-color: #00ffea;
    --bg-color3: #45395a;
} */

[data-theme="l"] {
    --primary-color:lightseagreen;
    --secondary-color: rgb(243, 71, 85);
    --tertiary-color: #bebebe;
    --font-color: #221e24;
    --font-color2: #56485e;
    --font-color3: rgb(168, 160, 167);
    --bg-color: rgb(255, 255, 255);
    --heading-color: #10b1a3;
    --bg-color2: rgb(233, 229, 233);
    --bg-color2-2: rgb(235, 235, 235);
    --bg-color3: rgb(182, 172, 180);
    --bg-color4: var(--bs-gray-100);
}


[data-theme="d"] {
    --primary-color: lightseagreen;
    --secondary-color: rgb(211, 62, 75);
    --tertiary-color: #c9c0c0;
    --font-color: #e1e1ff;
    --font-color2: #cabdc6;
    --font-color3: #605279;
    --bg-color: #161625;
    --bg-color2: #3b335e;
    --bg-color2-2: #292341;
    --heading-color: #00ffea;
    --bg-color3: #45395a;
    --bg-color4: #1e1e33;
}