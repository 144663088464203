/* Reset styles */
.login-register-page,
.login-register-page * {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  /* margin: 0; */
  padding: 0;
  color: inherit;
  background-color: inherit;
  /* border:2px solid orange; */
}
/* body  */
.login-register-page {
  /* background-image: url(pic.jpg); */
  /* background-image: url(/src/temp/pic.png); */
  background-size: cover;
  background-position: center center;
  padding: 3% 0;
  min-height: 100vh;
  align-items: center;
  display: flex;
  /* background: rgb(72,210,203);
background: linear-gradient(174deg, rgba(72,210,203,1) 0%, rgba(53,125,131,1) 60%, rgba(57,26,58,1) 100%); */
  /* background: rgb(72,210,203);
background: linear-gradient(174deg, rgba(72,210,203,1) 0%, rgba(197,202,203,1) 55%, rgba(158,158,158,1) 100%); */
  /* To fit the whole page */
  width: 100%;
  height: 100%;
}
.login-register-page .container {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 100px;
}

.login-register-wrapper {
  /* height: 560px; */
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2em;
  border-radius: 5px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  /* color: #ffffff;
  background: linear-gradient(
    180deg,
    rgba(19, 38, 79, 1) 0%,
    rgba(22, 147, 198, 1) 52%,
    rgba(255, 255, 255, 0) 82%
  ); */
  background-color: var(--bg-color);
}

[data-theme="d"] .login-register-wrapper {
  box-shadow: rgb(200 200 211 / 20%) 0px 7px 29px 0px;
}

@media (max-width: 1000px) {
  .login-register-wrapper {
    width: 50%;
  }
}

@media (max-width: 500px) {
  .login-register-wrapper {
    width: 100%;
  }
}

/* Nav buttons */
.login-register-page .nav-buttons {
  width: 100%;
  height: 100px;
  padding-top: 40px;
  opacity: 1;
  margin-bottom: 3em;
}
.login-register-page button {
  font-size: 1.5em;
  margin-right: 1em;
  border: 0px;
  cursor: pointer;
}
.login-register-page button:focus {
  outline: 0px;
}
.login-register-page button.active {
  padding-bottom: 10px;
  border-bottom: solid 2px var(--primary-color);
}

/* footer: forgot section */
.login-register-page .forgot-panel {
  position: relative;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 24px;
  /* border-top: solid 1px rgba(255, 255, 255, 0.3); */
  border-top: solid 1px var(--font-color);
}

/* form styling */
.login-register-page .form-group input,
.login-register-page .form-group select {
  width: 100%;
  height: 35px;
  /* padding-left: 15px; */
  padding: 0 3%;
  border: none;
  border-radius: 5px;
  /* margin-bottom: 20px; */
  background-color: var(--bg-color2);
  /* background: rgba(255, 255, 255, 0.5); */
  outline: none;
}

.login-register-page .form-group label {
  color: var(--secondary-color);
  /* color: rgb(138, 14, 14); */
  font-family: "Vazir";
  font-size: large;
  margin-top: 20px;
}
.login-register-page input.submit {
  font-weight: 500;
  font-family: "Vazir";
  text-transform: uppercase;
  font-size: 1em;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  padding: 8px 0px;
  width: 100%;
  height: 35px;
  border: none;
  border-radius: 20px;
  margin-top: 23px;
  background-color: var(--secondary-color);
}

/*Form positioning and animation */

.login-register-page form#registerform {
  left: 500px;
  top: -230px;
  position: relative;
}

.login-register-page .forgot-panel {
  margin-top: -300px;
  color: gray;
}

.login-register-page form,
.login-register-page .forgot-panel {
  position: relative;
  transition: all 0.5s ease;
}
.login-register-page #loginBtn,
.login-register-page #registerBtn {
  transition: all 0.5s ease;
}

.login-register-page .fullname-container {
  display: inline-flex;
  direction: rtl;
  width: 100%;
}

/* .login-register-page.fullname-container label{
  margin: 0 auto;
} */

.login-register-page .fullname-container input:first-of-type {
  margin-left: 5px;
}
.login-register-page .fullname-container input:nth-of-type(3) {
  margin-right: 5px;
}

.login-register-page #date {
  text-align: center;
}

.login-register-page .feedback-text {
  margin: 3px auto;
  font-family: "Vazir";
  font-weight: bold;
  font-size: 0.8rem;
  color: firebrick;
  direction: rtl;
}

.login-register-page input:-webkit-autofill,
.login-register-page input:-webkit-autofill:hover,
.login-register-page input:-webkit-autofill:focus,
.login-register-page input:-webkit-autofill:active {
  background-color: var(--bg-color2) !important;
  -webkit-box-shadow: 0 0 0 30px var(--bg-color2) inset !important;
  -webkit-text-fill-color: var(--font-color) !important;
}
