.dataset-list{
    display: grid;
    flex-direction: column;
    align-items: center;
    white-space: break-spaces;
}

.dataset-list table {
    width: 100%;
    direction: rtl;
    color: var(--font-color);
  }

.dataset-list thead{
    /* background-color: var(--bg-color2); */
    border-bottom: 2px solid var(--bg-color3);
    color: var(--primary-color);
}

.dataset-list th{
    padding: 5px;
}

.dataset-list tbody{
    padding: 5px;
    font-family: 'Vazir';
    font-size: .9rem;
    font-weight: 700;
    color: var(--font-color2);
}

.dataset-list .main-row{
    border: 1px solid var(--bg-color2);
    /* box-shadow: rgb(149 157 165 / 10%) 0px 8px 24px; */
}

.dataset-list td{
    border: 1px solid var(--bg-color2);
    padding:5px;
}

.dataset-list .answers-container .answer:last-of-type{
    border-bottom: none;
}

.dataset-list .answer{
    padding: 5px;
    border-bottom: 1px solid var(--bg-color2);
    width: 100%;
}

.dataset-list .cell:hover{
    color: var(--font-color3);
}

.dataset-list img{
    cursor: pointer;
    width: 80%;
    height: 100%;
    object-fit: contain;
}

.dataset-list .image-cell{
    width: 35%;
}

.dataset-list .question-cell{
    width: 40%;
}

.dataset-list .answer-cell{
    width: 25%;
}

.scenario2-dataset-list .image-cell{
    width: 35%;
}

.scenario2-dataset-list .question-cell{
    width: 20%;
}

.scenario2-dataset-list .short-cell{
    width: 10%;
}

.scenario2-dataset-list .long-cell{
    width: 35%;
}

@media(max-width:768px){
    .dataset-list .image-cell{
        width: 30%;
    }
    
    .dataset-list .question-cell{
        width: 40%;
    }
    
    .dataset-list .answer-cell{
        width: 30%;
    }

    .dataset-list img{
        width: 90%;
    }

    .scenario2-dataset-list .image-cell{
        width: 30%;
    }
    
    .scenario2-dataset-list .question-cell{
        width: 25%;
    }
    
    .scenario2-dataset-list .short-cell{
        width: 10%;
    }
    
    .scenario2-dataset-list .long-cell{
        width: 35%;
    }
}

.dataset-list textarea{
    border: none;
    resize: none;
    height: 100%;
    width: 100%;
    text-align: center;
    color: var(--font-color2);
    background-color: var(--bg-color);
}

.dataset-list textarea:focus-visible{
    outline: none;
}

.dataset-list .btn{
    color: var(--bg-color);
    background-color: var(--primary-color);
}

.dataset-list .author{
    color: var(--font-color);
    font-size: 0.8rem;
    font-weight: normal;
}

.dataset-list .approved_by{
    color: var(--font-color);
    font-size: 0.8rem;
    font-weight: normal;
}

.dataset-list .time{
    color: var(--font-color);
    font-size: 0.8rem;
    font-weight: normal;
    white-space: pre;
}

.dataset-list .category{
    color: var(--font-color);
    font-size: 0.8rem;
    font-weight: normal;
}

.dataset-list .pagination-container{
    margin: auto;

}

.dataset-list .border-top{
    border-top: 3px solid var(--bg-color2) !important;
}

.dataset-list .border-top2{
    border-top: 2px solid var(--bg-color2) !important;
}

.dataset-list .sum-row{
    background: var(--bg-color2-2);
}