@import "../../utilities/references/index";

.ant-modal-body {
    direction: rtl;
    padding-top: 50px !important;

    .textmodal {
        text-align: justify;
    }
}


// body {
//     height: 100%;
//     margin: 0;

.userbackground {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    align-items: center;
    text-align: justify;
    background: url(../../assets/images/2.jpg) center;
    background-size: cover;
    background-repeat: no-repeat;

    .usercontent {
        display: flex;
        width: 90%;
        height: fit-content;
        direction: rtl;

        margin: auto;
        // margin-left: 5%;
        // margin-right: 5%;
        // margin-top: auto;
        // margin-bottom: auto;

        flex-direction: column;
        background-color: $white;
        opacity: 90%;
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
        border-radius: 15px;

        @include project-md {
            width: 50%;
        }


        .fill-button {
            display: flex;
            flex-direction: row-reverse;
            margin-top: 10px;
            margin-right: auto;
            margin-left: auto;

            .MuiButtonBase-root{
                background-color: $blueDark;
                color: white;
            }
        }

        .userInfo {
            text-align: center;

            .userItems {
                display: flex;
                flex-direction: column;
                border-style: solid;
                border-color: $grayLight;
                border-width: 1px;
                padding: 10px;
                margin-top: 10px;
                border-radius: 15px;
                align-items: center;


            }
        }

    }
}