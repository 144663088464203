@font-face {
    font-family: Vazir;
    font-style: normal;
    font-weight: normal;
    src: url('fonts/Vazir.eot');
    src: url('fonts/Vazir.eot?#iefix') format('embedded-opentype'),
        url('fonts/Vazir.woff2') format('woff2'),
        url('fonts/Vazir.woff') format('woff'),
        url('fonts/Vazir.ttf') format('truetype');
}

@font-face {
    font-family: Vazir;
    font-style: normal;
    font-weight: bold;
    src: url('fonts/Vazir-Bold.eot');
    src: url('fonts/Vazir-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/Vazir-Bold.woff2') format('woff2'),
        url('fonts/Vazir-Bold.woff') format('woff'),
        url('fonts/Vazir-Bold.ttf') format('truetype');
}

body {
    font-family: 'Vazir', Arial, sans-serif;
    font-weight: normal;
}

.back {
    display: flex;
    width: 100%;
    height: max-content;
    position: absolute;
    text-align: justify;
    color: whitesmoke;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
}

p {
    color: black;
}

h3 {
    color: black;
}

.content-info {
    display: flex;
    width: 100%;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: auto;
    margin-bottom: auto;
    flex-direction: column;
    background-color: rgb(167, 167, 167);
    opacity: 90%;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    border-radius: 15px;
    font-size: large;
}

.info {
    text-align: justify;
    direction: rtl;
    padding-bottom: 5%;
}