.landing{
}

.landing h1, .landing h2{
    color: var(--heading-color);
    font-family: 'yekan';
}

.csection{ /*custom section*/
    min-height: 70vh;
    width: 100%;
    background-color: var(--bg-color);
    padding: 5% 0;
}

.landing .csection:first-of-type{
    /*rosybrown, slateblue, lightslategrey */
    background: teal;
    background:
    /* radial-gradient(farthest-side,yellow 32%,yellow 90% ,purple 93%, purple 96%,transparent) left center/60px 60px,
    radial-gradient(farthest-side,rgba(255,255,255,0.5)  96%,transparent) bottom left/80px 80px,
    radial-gradient(farthest-side,green  96%,transparent) top right/80px 80px,
    radial-gradient(farthest-side,rgba(255,255,255,0.5)   96%,transparent) 300px 50px/100px 100px,
    radial-gradient(farthest-side,red    96%,transparent) top left/40px 40px, */
    linear-gradient(150deg, rgba(46,248,194,1) 0%, rgba(58,104,160,1) 73%, rgb(85, 49, 114) 100%);
    background-repeat:no-repeat;
    min-height: calc(100vh - 56px);
    grid-template-columns: 56% 44%;
    padding: 1% 0;
}

[data-theme="d"] .landing .csection:first-of-type{
    background: linear-gradient(150deg, rgb(37, 221, 172) 0%, rgb(35, 71, 116) 73%, rgb(85, 49, 114) 100%);
}

/* @media(max-width:547px){
    .landing .csection:first-of-type{
        min-height: calc(100vh - 80px);
    }
  } */

.csection-centered-items{
    display: grid;
    align-items: center;
    justify-content: center;
}

.csection-right-text{
    display: grid;
    grid-template-columns: 65% 35%;
}

.csection-right-text .csection-text{
    grid-column-start: 2;
    grid-row-start: 1;
    text-align: right;
    justify-content: right;
}

.csection-right-text .csection-img-container{
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 2;
    align-items: center;
    align-self: center;
}

.csection .csection-img-container img{
    object-fit: contain;
    width: 100%;
    height:100%;
}

.csection-left-text .csection-text{
    grid-column-start: 1;
    grid-column-end: 3;
    text-align: left;
    justify-content:  left;
}

.csection-left-text{
    display: grid;
}

.csection-left-text .csection-img-container{
    grid-row-start: 1;
    grid-column-start: 3;
    align-items: center;
    align-self: center;
}

.csection-text{
    align-self: center;
    padding: 10%;
    direction: rtl;
    font-family: 'Vazir';
}


.landing .carousel-item{
    width: 100%;
    /* height: 60vh; */
}

/* .landing .carousel-item img{
    width: 90%;
    height: 100%;
    object-fit: contain;
} */
.landing .carousel .carousel-control-next-icon {
  
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='7' height='7' viewBox='-2 -2 11 12'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"),
   url('data:image/svg+xml,<svg width="8" height="8" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 8 8"><desc>Example circle01 - circle filled with red and stroked with blue</desc><circle cx="4" cy="4" r="4" fill="teal"/></svg>'); 
 }
 .landing .carousel .carousel-control-prev-icon{
   background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='7' height='7' viewBox='-1 -2 11 12'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"),
   url('data:image/svg+xml,<svg width="8" height="8" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 8 8"><desc>Example circle01 - circle filled with red and stroked with blue</desc><circle cx="4" cy="4" r="4" fill="teal"/></svg>'); 
 }

 .landing .btn{
    background-color: var(--secondary-color);
    color: white;
    margin: 1px;
 }


 /* temp */
 .csection-intro{
    /* background-image:url("../../assets/images/abstract-technology-background.jpg");
    background-repeat: no-repeat;
    background-size: cover; */
 }


 /***** HEADING PICTURE *****/
@mixin triangle($color, $size, $direction) {
  width: 0;
  height: 0;
  @if $direction == "up" {
    border-right: ($size + px) solid transparent;
    border-left: ($size + px) solid transparent;
    border-bottom: ($size + px) solid $color;
  }
  @if $direction == "down" {
    border-right: ($size + px) solid transparent;
    border-left: ($size + px) solid transparent;
    border-top: ($size + px) solid $color;
  } 
  @if $direction == "right" {
    border-top: ($size + px) solid transparent;
    border-bottom: ($size + px) solid transparent;
    border-left: ($size + px) solid $color;
  }
  @if $direction == "left" {
    border-top: ($size + px) solid transparent;
    border-bottom: ($size + px) solid transparent;
    border-right: ($size + px) solid $color;
    }
}

 .csection .header-img-container{
    padding: 0 5vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
 }
 .csection .header-img-inner{
    /* max-height: 70vh; */
    /* padding: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: auto;
    overflow-x: hidden;
    width: min-content; */
}

 .csection .header-img-container img{
    border-radius: 10px;
    /* margin-bottom: 20px; */
 }

 .csection .header-img-container .message{
    margin-bottom: 1vmin;
    transition:1s;
    -webkit-transition:1s;
    -moz-transition:1s;
    display: inline-flex;
    /* transition-delay: 250ms; */
    /* transition-property: margin; */
    /* width: fit-content; */
}

.csection .header-img-container .message p{
    margin: 0;
    font-size: 3vh;/*x-large;*/
    font-family: 'Roya';
    font-weight: 600;
    color: darkslategray;
    /* display:run-in; */
    /* transition:1s;
    -webkit-transition:1s;
    -moz-transition:1s; */
    /* transition-delay: 250ms; */
    /* transition-property: margin; */
    white-space: nowrap;
    direction: rtl;
}

@media(max-width:430px) {
    .csection .header-img-container .message p{
        white-space:initial;
    }
}


.csection .header-img-container .right-message{
    margin-left: 200%;
    margin-right: -100%;
}

.csection .right-message-visible{
    align-self: flex-end;
    margin-left: 0% !important;
    margin-right: 0% !important;
}

.csection .header-img-container .left-message{
    margin-right: 200%;
    margin-left: -100%;
}

.csection .left-message-visible{
    /* align-self: flex-end; */
    align-self: flex-start;
    margin-right: 0% !important;
    margin-left: 0% !important;
}

.csection .right-message p{
    text-align: right;
}

.csection .left-message p{
    text-align: left;
}
/* 
.arrow-left {
    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    
    border-right:10px solid rgba(255, 255, 255, 0.5); 
  } */

.csection .message-inner{
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    padding: 15px;
    position: relative;
}

.csection .left-message .message-inner:after {
    content: "";
    position: absolute;
    margin-top: -6px;
    /* margin-right: 1px; */
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid rgba(255, 255, 255, 0.5);
    transform: rotate(
45deg
);
    left: -16px;
    top: 10px;
}

.csection .right-message .message-inner:after{
    content: "";
    position: absolute;
    margin-top: -6px;
    /* margin-right: 1px; */
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid rgba(255, 255, 255, 0.5);
    transform: rotate(
-45deg
);
    right: -16px;
    top: 10px;
}

.dataset-img{
    /* background: url('/src/Dataset/dataset-light.jpg'); */
    width: 100%;
    height: 100vh;
    border-radius: 3vw;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

[data-theme='d'] .dataset-img{
    /* background: url('/src/Dataset/dataset-dark.jpg'); */
    box-shadow: rgb(200 200 211 / 20%) 0px 7px 29px 0px;
}

.csection-centered-items .csection-text{
    max-width: 60vw;
    align-self: center;
    justify-self: center;
}

@media(max-width:700px){
    .landing .csection:first-of-type{
        grid-template-rows: 40% 60%;
        grid-template-columns: 100%;
    }
    .csection-right-text .csection-text{
        grid-row-start: 2;
        grid-column-start: 1;
    }

    .csection-right-text  .csection-first-text{
        align-self: end;
    }

    .csection-right-text .csection-img-container{
        grid-column-start: 1;
        grid-row-start: 1;
        grid-row-end: 2;
        height: 100%;
    }
    .csection-left-text .csection-text{
        grid-row-start: 1;
        grid-row-end: 3;
    }
    .csection-left-text .csection-img-container{
        grid-column-start: 1;
        grid-row-start: 3;
    }

    .csection-right-text{
        grid-template-columns: auto;
    }
    
  }

  .csection .csection-text{
    max-width: 70vw;
  }

  @media(max-width:450px){
    .csection .csection-text{
      max-width: 90vw;
    }
  }

  .blog-post-holder{
      width: 100%;
      padding: 2% 10%;
  }

  .landing .carousel-control-next, .landing .carousel-control-prev{
      width: 10%;
  }

  .carousel .sr-only{
    display: none;
  }
/* 
  .csection p{
    text-align: justify;
  } */