@import "../../utilities/references/index";

.ant-modal-body{
    direction: rtl;
    padding-top: 50px !important;

    .textmodal{
        text-align: justify;
    }
}

// body{
//     height: 100%;
//     margin: 0;

    .background-idtwitter{
        display: flex;
        width: 100%;
        height:100%;
        position: absolute;
        //align-items: center;
        text-align: justify;
        background: url(../../assets/images/2.jpg) center;
        background-size: cover;
        background-repeat: no-repeat;
        font-size: 130%;
    
        .content{
            display: flex;
            width: 100%;
            height: fit-content;
            direction: rtl;
            //position: relative;
            margin-left: 5%;
            margin-right: 5%;
            margin-top: auto;
            margin-bottom: auto;
            flex-direction: column;
            background-color: $white;
            opacity: 90%;
            padding: 20px;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;    
            border-radius: 15px;

            b{
                font-size: medium;
            }

            .btns{
                display: flex;
                flex-direction: row-reverse;
                margin-top: 15px;
                margin-right: auto;
                margin-left: auto;


                .Nobtn{
                    width: 80px;

                    .project-buttons{
                        padding: 15px;
                        background-color: $red;
                    }
                }

                .Yesbtn{
                    width: 80px;
                    margin-left: 15px;

                    .project-buttons{
                        padding: 15px;
                    }
                }
            }

            .getIdBox{
                margin-top: 10px;
                display: flex;
                flex-direction: column;

                .btninpBox{
                    display: flex;
                    flex-direction: row;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 2%;
                    
                    .inpBox{
                        width: 65%;
                        display: flex;
                        direction: ltr;
                        padding-left: 5px;
                    }

                    .project-buttons{
                        width: 45%;
                        display: flex;
                        margin-right: 10px;
                        background-color: $yellow;
                        color: black;
                    }
    
                }
            }
    
        }
    }
    
//}
