@import "../../utilities/references/index";
.Final{
    .scroll {

        overflow-y: scroll;
    }

    .background {
        display: flex;
        width: 100%;
        height: max-content;
        position: absolute;
        text-align: justify;
        background: url(../../assets/images/2.jpg) center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;

        .thanks-content {
            display: flex;
            width: 100%;
            height: max-content;
            margin-left: 5%;
            margin-right: 5%;
            margin-top: 25px;
            margin-bottom: 25px;
            flex-direction: column;
            background-color: $white;
            opacity: 90%;
            padding: 20px;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
            border-radius: 15px;
            font-size: large;

            .thanks-info {
                text-align: justify;
                direction: rtl;
                padding-bottom: 5%;

                .code-box {
                    text-align: justify;
                    display: flex;
                    width: 100%;
                    margin-bottom: 2%;
                    direction: rtl;
                    background-color: rgb(170, 224, 231);
                    opacity: 90%;
                    padding: 20px;
                    box-shadow: rgba(0, 0, 0, 0.125) 0px 7px 14px, rgba(0, 0, 0, 0.22) 0px 5px 5px;
                    border-radius: 5px;
                    font-size: large;
                    flex-direction: column;

                    @include project-md {
                        width: 80%;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    .Code {
                        display: flex;
                        margin-left: 5%;
                        margin-right: 5%;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;


                        .Code-text {
                            display: flex;
                            flex-direction: column;
                            background-color: rgb(58, 204, 209);
                            margin-bottom: 1%;
                            margin-top: 1%;
                            opacity: 90%;
                            padding: 20px;
                            box-shadow: rgba(0, 0, 0, 0.125) 0px 7px 14px, rgba(0, 0, 0, 0.22) 0px 5px 5px;
                            border-radius: 5px;
                            font-size: large;
                            direction: ltr;
                            text-align: center;
                            text-overflow: ellipsis;

                            .btn-color {
                                margin-left: 5%;
                                margin-right: 5%;
                                background-color: rgb(58, 204, 209);
                            }
                        }

                    }
                }

                p {
                    text-align: justify;
                }

                b {
                    font-weight: 500;
                }
            }

            .reference-text {
                display: flex;
                width: 100%;
                direction: rtl;
                background-color: $blueDark;
                color: white;
                opacity: 90%;
                padding: 20px;
                box-shadow: rgba(0, 0, 0, 0.125) 0px 7px 14px, rgba(0, 0, 0, 0.22) 0px 5px 5px;
                border-radius: 5px;
                font-size: small;

                @include project-md {
                    width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}