

.iust-xs{
    display: none;
}
@media (max-width: 899px){
    .iust-xs{
        display: flex;
    }
    .iust-md{
        display: none;
    }
}