@import "./fonts.scss";
@import "./themes";
// reset common styles
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

body,
button,
input {
  font-family: Vazir, sans-serif;
}


@function color($color-name) {
  @return var(--#{$color-name});
}
