.landing{
    width: 100%;
}

.landing-section{
    width: 100%;
    background-color: var(--bg-color);
    overflow: auto;
}

.landing-section-inner{
    margin: 5vh 20vw;
}

@media (max-width: 750px){
    .landing-section-inner{
        margin: 0 0;
    }
}

.main .landing h1{
    font-family: 'Inter-Regular';
    color: var(--heading-color);
    margin: 5vh 2vw 2vh 2vw;
    border-width: 5px;
    border-style: solid;
    border-image: linear-gradient( to right, var(--bg-color3), var(--bg-color) ) 1;
    border-top: none;
    border-left: none;
    border-right: none;
}

.landing-empty{
    height:200px;
}

.landing-upper{
    overflow: hidden;
}

.landing-upper img{
    width: 100vw;
    min-height: 200px;
    max-height: 400px;
    object-fit: cover;
}


/* horizontal scrolling list */
.card{
    margin: 5vw;
}

.card img{
    width: 100%;
}

.card h2{
    font-family: 'Manrope-SemiBold';
}

.card p{
    font-family: 'Inter-Regular';
}