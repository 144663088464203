.landing .card{
    margin: 10px 5vw;
}

.landing .card img{
    width: 100%;
    max-height: 40vh;
    object-fit: cover;
    height: auto;
}

.landing .card h2{
    font-family: 'Manrope-SemiBold';
    margin-top: 5px;
}

.landing .card p{
    font-family: 'Inter-Regular';
    white-space: break-spaces;
}

.landing .card{
    border: none;
}

.main .card a{
    margin: 5px 0;
}

/* .landing .react-horizontal-scrolling-menu--scroll-container {
    overflow-x: hidden;
} */

.main .contributor-card{
    /* background-color: var(--bg-color2-2);
    border-radius: 10px; */
    margin: calc(var(--bs-gutter-x) * .5) 0;
}

.main .contributors-header{
    grid-auto-columns: 25% auto;
    grid-auto-rows: 50% auto;
    display: grid;
}

.main .contributor-card .img-container {
    width: 100%;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 3;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative;
  }

.main .contributor-card img{
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
}

.main .contributor-card h6{
    grid-column-start: 2;
    grid-row-start: 1;
    align-self: end;
    margin: 0 5px;
}

.main .contributors-container{
    overflow: hidden;
    transition-property: all;
	transition-duration: .5s;
	transition-timing-function: cubic-bezier(1, 1, 1, 1);
}

.main .contributors-title{
    color: var(--secondary-color);
    cursor: pointer;
}

.main .contributor-card .join-date{
    grid-column-start: 2;
    grid-row-start: 2;
    align-self: start;
    margin: 0 5px;
    color: var(--font-color2);
}

.main .contributor-card .desc{
    margin: 0 10px;
}

.main .contributor-card .task{
    color: white;
    width: fit-content;
    display: inline-block;
    margin: 2px;
    padding: 2px 4px;
    border-radius: 5px;
    font-weight: 500;
    font-size: .9em;
}

.main .mySwiper .card{
    margin-bottom: 40px;
}



/* Swiper */
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  