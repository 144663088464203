@import "theme/main.scss";

.blog .editor-container {
  border: 1px solid color(bg-color2);
  margin: 5% 0;
}

.blog .rdw-dropdownoption-active {
  color: var(font-color);
}

.blog .blog-editor {
  background-color: color(bg-color);
}

.blog .blog .blog-toolbar {
  background-color: color(bg-color2-2);
  color: black;
}

.blog .blog-toolbar .rdw-option-wrapper,
.rdw-dropdown-wrapper,
.rdw-dropdown-optionwrapper,
.blog a,
.blog .rdw-image-imagewrapper {
  /* background-color: color(bg-color); */
  /* border: 1px solid color(bg-color2); */
  color: black;
}

.blog .preview-container {
  border: 1px solid color(bg-color2);
  margin: 5% 0;
  padding: 5px;
}

.blog .post-info-editor {
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
}

.blog .post-info-editor input,
.blog .post-info-editor textarea {
  width: 100%;
  margin-bottom: 5px;
}

/****** Posts ******/

.blog-page {
  padding: 5vh 0;
  display: flex;
  justify-content: center;
  /* width: 100vw; */
}

.blog {
  display: flex;
  justify-content: center;
  width: 100%;
}

.blog .post-container {
  width: 100%;
}

.blog .post-container .back {
  text-align: left;
  font-weight: bold;
}

@media (min-width: 650px) {
  .blog .non-preview-post-container {
    width: 74%;
    padding: 2% 2%;
    border: 2px solid color(bg-color2);
  }
}

.blog .post {
  width: 100%;
  align-items: unset;
  text-align: justify;
  display: contents;
}

.blog .post .header {
  /* direction: rtl; */
  padding: 2% 0;
}

.blog .post .header .img-container,
.blog-card .img-container {
  width: 100%;
  height: 40vh;
}

.blog .post .header img,
.blog-card img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.blog .post .header .title,
.blog-card .title {
  font-size: 2rem;
  font-weight: bold;
  color: color(font-color);
  text-decoration: none;
}

.blog .header .summary,
.blog-card .summary {
  white-space: break-spaces;
}

.blog .post .post-info {
  display: grid;
  grid-template-rows: auto auto;
}

.blog .post .post-info p {
  font-size: 0.8rem;
  font-weight: bold;
  color: color(font-color2);
  font-family: "yekan";
}

.blog .post-info .keywords {
  margin: 0 0 0 auto;
  grid-row-start: 0;
  grid-row-end: 1;
}

.blog .post-info .authors {
  grid-row-start: 1;
}

.blog .post-info .duration {
  grid-row-start: 1;
}

.blog .post p {
  max-width: 100%;
}

.blog .post ul,
.blog .post ol {
  padding-right: 2rem;
}

.blog .btn {
  background: color(tertiary-color);
  color: white;
}

.blog .btn:hover {
  background: color(primary-color);
}

.blog .save-btn {
  background: color(secondary-color);
  margin: 2%;
}

.blog blockquote {
  padding: 0 0.3rem;
  color: color(font-color2);
  border-left: none;
}

.blog pre {
  background: #f1f1f1;
  border-radius: 3px;
  padding: 1px 10px;
}

.blog img {
}

.blog a {
  color: color(primary-color);
  text-decoration: none;
}

.blog .post-inner {
  width: 100%;
  align-items: unset;
  text-align: justify;
  display: contents;
}

.blog-card {
  flex-direction: column;
}

.blog-list {
  max-width: 60vw;
  width: 100%;
}

@media (max-width: 450px) {
  .blog-list {
    max-width: 90vw;
  }
}

.blog-list .separator {
  border-bottom: 2px solid color(bg-color2);
  margin: 2% 2% 5% 2%;
}

.blog-card .edit-btn {
  margin-left: 5px;
}

.blog-card .delete-btn {
}

.blog-card .delete-btn:hover {
  background-color: red;
}

.blog-card .buttons {
  display: flex;
  direction: rtl;
  flex-direction: row-reverse;
}

.blog img,
.post img {
  max-width: 100%;
  max-height: 100%;
}

.preview-container .post h1,
.blog-editor h1 {
  color: color(font-color);
  font-family: color(bs-body-font-family);
}
