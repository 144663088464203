.main-page-footer{
    padding: 4vh 0 0 0;
    background-color: var(--font-color2);
    color: var(--bg-color);
}

.main-page-footer .row{
    overflow: hidden;
    width: 100%;
}

.main-page-footer h1{
    color: var(--bg-color4);
    font-family: 'Manrope-SemiBold';
    font-size: 1.7rem;
}

.main-page-footer p{
    color: var(--bg-color2);
    font-family: 'Manrope-SemiBold';
}

.main-page-footer .section{
    margin: 15px;

}

.main-page-footer .item{
    display: grid;
    grid-auto-columns: min-content auto;
    margin-bottom: 1em;
}

.main-page-footer .item svg{
    grid-column-start: 1;
    grid-column-end: 2;
    margin-right: 7px;
}

.main-page-footer .item p{
    grid-column-start: 2;
    margin: 0;
    font-family: 'Inter-Regular';
}

.main-page-footer .copy-right{
    padding: 0 30px 5px 30px;
    font-family: 'Inter-Regular';
    font-size: 1.1em;
    color: var(--bg-color3);
    text-align: center;
    border-top: 1px var(--bg-color3) solid;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
}

.main-page-footer .follow-us{
    margin-bottom: 10vh;
}

.main-page-footer .follow-us a{
    color: inherit;
    margin-right: 5px;
}

.main-page-footer .follow-us svg{
    font-size: 2.5em;
}