.custom-nav{
    z-index: 999;
    /* width: 100vw; */
    background-color: var(--bg-color);
    box-shadow: var(--bg-color3) 0px 7px 29px 0px;
    /* height: 56px; */
    /* background-color: var(--secondary-color); */
}

/* @media(max-width:640px){
    .custom-nav{
        height: 120px;
    }
} */

.custom-nav ,.custom-nav .navbar-brand, .custom-nav .navbar-brand a, .custom-nav .navbar-brand a:hover{
    color: var(--primary-color);
    text-decoration: none;
}


.custom-nav .navbar-brand .btn{
    padding: 0px .5rem 0px .5rem;
    margin-top: -10px;
    color:var(--font-color);
}

.nav-profile img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid var(--primary-color);
    background-color: white;
    padding: 0;
    border: none;
}

.navbar-light .navbar-nav .nav-link, .custom-nav .no-profile,.navbar-light .navbar-nav .nav-link:hover, .custom-nav .no-profile:hover{
    color: var(--font-color2)
}

.nav-profile img{
    object-fit: cover;
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
    align-self: center;
}

@media(max-width:449px){
    .navbar-expand .navbar-nav .nav-link {
        padding-right: 0.3rem;
        padding-left: 0.3rem;
    }
  }