.demo-page-inner {
  border: 1px solid var(--bg-color2-2);
  padding: 5%;
}

.demo-page {
  padding: 5% 10%;
  border-radius: 5%;
}

@media (max-width: 500px) {
  .demo-page {
    padding: 10% 0;
  }
  .demo-page-inner {
    padding: 2%;
  }
}

.demo {
  position: relative;
}

.demo .img-container img {
  max-width: 100%;
  max-height: 60vh;
  border-radius: 3vw;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px; */
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px; */
}
[data-theme="d"] .demo .img-container img {
  box-shadow: rgb(200 200 211 / 20%) 0px 7px 29px 0px;
}

.demo .img-container {
  padding: 10px 5%;
}

.demo .dropzone {
  /* background: var(--bg-color2-2); */
  padding: 5px;
  cursor: pointer;
  position: relative;
  border-radius: 5vw;
  border: 2px solid var(--bg-color);
  transition: ease-in-out;
}

.demo .dropzone:hover {
  border: 2px solid var(--primary-color);
  border-style: dashed;
}

.demo .dropzone p {
  margin: 0;
  font-size: 0.85rem;
  font-weight: bold;
  font-family: "Vazir";
  color: var(--font-color2);
  direction: rtl;
}

.demo textarea {
  border: 2px solid var(--tertiary-color);
  border-radius: 10px;
  width: 95%;
  resize: none;
  margin: 5vh 0;
  direction: rtl;
  padding: 3vh 5vw;
  background: var(--bg-color);
  color: var(--font-color);
  box-shadow: rgb(149 157 165 / 30%) 0px 8px 24px;
  font-family: "yekan";
  font-size: 1.1rem;
  transition: ease-in-out 0.2s;
  caret-color: var(--primary-color);
}

.demo textarea:focus {
  outline: none;
  border: 2px solid var(--primary-color);
  /* box-shadow: rgba(63, 150, 119, 0.4) 0px 7px 29px 0px; */
  box-shadow: rgba(63, 150, 119, 0.45) 0px 5px 15px 0px;
  width: 100%;
}

.demo .feedback-text {
  margin: 3px auto;
  font-family: "Vazir";
  font-weight: bold;
  font-size: 0.8rem;
  color: firebrick;
  direction: rtl;
}

.demo .dropzone-hover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(144, 144, 144, 0.6);
  /* border-radius: 5vw; */
  justify-content: center;
  align-items: center;
}

.demo .dropzone-hover p {
  font-size: 0.9rem;
  color: var(--primary-color);
}

.demo .q-submit-field {
  display: flex;
  flex-direction: row;
  direction: rtl;
}

.demo .q-submit-field .btn {
  margin: 5vh 2vw;
  background-color: var(--bg-color2-2);
  border: 1px solid var(--bg-color3);
  color: var(--font-color2);
  /* align-items: center; */
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.demo .q-submit-field .btn-inner {
  display: inline-flex;
  font-size: 1.2rem;
  font-family: "yekan";
}

.demo .q-submit-field .btn:hover,
.demo-loading-answer-inner .btn:hover {
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  color: white;
}

.demo .q-submit-field .btn svg {
  width: 0;
  transition: cubic-bezier(0.23, 1, 0.32, 1) 0.2s;
  font-size: 2.2rem;
}

.demo .q-submit-field .btn:hover svg {
  width: 1.5rem;
}

.answer-field-inner {
  /* position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0; */
  /* background-color: rgba(144,144,144,0.2); */
  /* z-index: 900; */
  display: flex;
  justify-content: center;
  /* padding: 5%; */
  height: 100%;
}

.demo-loading-answer-inner {
  /* background-color: var(--bg-color); */
  /* border: 3px solid var(--bg-color2-2); */
  align-self: center;
  padding: 5%;
  position: relative;
}

.demo-loading-answer-inner h5 {
  margin: 0;
  font-family: "Vazir";
  font-weight: bold;
  font-size: 1.1rem;
}

.demo-loading-answer-inner p {
  color: var(--font-color2);
  margin: 5px 0 0 0;
  font-weight: 500;
  font-family: "yekan";
  font-size: 1.1rem;
}

.demo-loading-answer-inner .btn {
  background-color: var(--bg-color2);
  border: 1px solid var(--bg-color3);
  color: var(--font-color2);
  font-family: "yekan";
}

.demo .row {
  flex-direction: row-reverse;
}

.demo .answer-field {
  /* background-color: var(--bg-color2); */
  border-right: 2px solid var(--bg-color3);
  width: 100%;
  height: 100%;
}

.demo button:focus {
  outline: none;
  box-shadow: none;
}

.demo .demo-not-asked-answer {
  height: 100%;
  display: flex;
}

.demo .demo-not-asked-answer p {
  margin: auto;
}

.demo .demo-answers {
  direction: rtl;
  width: 100%;
  padding: 1vh 1vw;
}

.demo .best-answer {
  display: flex;
  flex-direction: column;
}

.demo .best-answer p {
  align-self: flex-start;
  color: var(--font-color3);
  font-family: "Vazir";
  margin: 0;
  font-size: 0.9rem;
}

[data-theme="d"] .demo .best-answer p {
  font-weight: bold;
}

.demo .best-answer h2 {
  color: var(--font-color);
  font-weight: bold;
  font-family: "yekan";
  align-self: center;
  font-size: 2rem;
}

.demo .answers {
  display: flex;
  flex-direction: column;
}

.demo .answers h4 {
  align-self: flex-start;
  color: var(--font-color3);
  font-family: "Vazir";
  margin: 15px 0 8px 0;
  font-size: 1rem;
}

.demo .answer-and-percentage {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  padding: 4px 15px;
}

.demo .answer-and-percentage p {
  font-family: "Rezvan";
  font-weight: bold;
  margin: 0;
  text-align: right;
}

.demo .answer-and-percentage .percentage {
  display: flex;
}

.demo .answer-and-percentage .percentage p {
  align-self: center;
}

.demo .answer-and-percentage .percentage .bar {
  width: 15vw;
  background: var(--bg-color2);
  height: 1.1rem;
  margin-right: 5px;
  align-self: center;
}

.demo .answer-and-percentage .percentage .filled-bar {
  background-color: var(--primary-color);
  height: 100%;
  border-radius: 3px 0 0 3px;
}

/* has one column */
@media (max-width: 991px) {
  .demo .answer-field {
    /* border-top: 2px solid var(--bg-color3); */
    border-right: none;
  }

  .demo .demo-not-asked-answer p {
    display: none;
  }

  .demo .answer-and-percentage .percentage .bar {
    width: 30vw;
  }
}

.page-title-description {
  display: flex;
  flex-direction: column;
  color: var(--font-color);
  align-items: center;
}

.page-title-description h1 {
  margin: 0.5rem 0 1rem 0;
  font-family: "yekan";
  font-weight: bold;
  color: var(--heading-color);
}

.page-title-description .description {
  direction: rtl;
  text-align: justify;
  font-family: "Vazir";
  margin-bottom: 0.5rem;
  max-width: 60vw;
}

.page-title-description p{
  max-width: 60vw;
  text-align: justify;
}

@media(max-width:450px){
  .page-title-description .description {
    max-width: 90vw;
  }
  
  .page-title-description p{
    max-width: 90vw;
  }
}

/**
 * ==============================================
 * Dot Flashing
 * https://github.com/nzbin/three-dots
 * ==============================================
 */

.demo .stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1rem 0;
  margin: 0 -5%;
  overflow: hidden;
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: var(--primary-color);
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: var(--primary-color);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: var(--primary-color);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: var(--primary-color);
  }
  50%,
  100% {
    background-color: var(--bg-color2);
  }
}
