.main .people-mini-view{
    display: grid;
    
}

.main .people-mini-view .people-link{
    font-size: large;
    font-family: 'Inter-Regular';
    text-align: center;
    margin: 0 auto 2vh;
    width: fit-content;
}

.main .people-mini-view .people-card .img-container {
    width: 80%;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 3;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative;
  }

.main .people-mini-view .people-card img{
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
}

.main .people-mini-view .row{
    width: 100%;
    padding: 0 2vw;
}

.main .people-mini-view .people-card{
    margin: 5px;
    text-align: center;
    cursor: pointer;
}

.main .people-mini-view .people-card:hover{
    transform: scale(1.01);
    transition: all ease-in-out;
}

.main .people-card h6{
    margin-top: 5px;
    font-size: 1.1rem;
    font-weight: bold;
    font-family: 'Manrope-SemiBold';
}

.main .people-card .role{
    font-family: monospace;
    color: var(--font-color2);
    font-size: 0.95rem;
}