@import "../../utilities/references/index";

.Toastify__toast-body{
  direction: rtl;
  font-weight: 500;
}

.questionnaire {
  .scroll {
    overflow-y: scroll;
  }

  .background {
    display: flex;
    width: 100%;
    height: max-content;
    position: absolute;
    //align-items: center;
    text-align: justify;
    background: url(../../assets/images/2.jpg) center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;

    .content {
      display: flex;
      width: 100%;
      height: fit-content;
      
      margin-left: 5%;
      margin-right: 5%;
      margin-top: 25px;
      margin-bottom: 25px;
      flex-direction: column;
      background-color: $white;
      opacity: 90%;
      padding: 20px;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
        rgba(0, 0, 0, 0.22) 0px 10px 10px;
      border-radius: 15px;
      font-size: large;
      direction: rtl;

      .line {
        display: flex;
        width: 100%;
        height: 47px;
        border-bottom: 1px solid black;
        margin-top: -15px;
      }

      .paginate {
        width: 100%;
        display: flex;
        margin-top: 5%;
        margin-bottom:2%;
        margin-right: auto;
        margin-left: auto;
        flex-direction: column;

        .btn {
         
          display: flex;
          width: 30%;
          margin-top: 5%;
          margin-right: auto;
          margin-left: auto;
          background-color: $blueDark;
          border: none;
          font-weight: bold;
          border-radius: 5px;
          color: $white;
          display: flex;
          align-items: center;
          justify-content: center;
          
          // .project-buttons{
          //   background-color: $blueDark;
          // }
  
        }


        .paginationBttns {
          width: 100%;
          height: 100%;
          margin-top: 1%;
          margin-bottom: 1%;
          list-style: none;
          display: flex;
          justify-content: center;
        }
       .hide{
         display: none;
       }
        .paginationBttns a {
          padding: 10px;
          margin: 8px;
          border-radius: 5px;
          border: 1px solid $blueDark;
          color: $blueDark;
          cursor: pointer;
        }

        .paginationBttns a:hover {
          color: white;
          background-color: $blueDark;
        }

        .paginationActive a {
          color: white;
          background-color: $blueDark;
        }

        .paginationDisabled a {
          color: grey;
          background-color: grey;
        }
      }
      

      .questions {
        display: flex;
        width: 100%;
        margin-top: 15px;
        flex-direction: column;

        .q {
          display: flex;
          flex-direction: row;
          border-style: solid;
          border-color: $grayLight;
          border-width: 1px;
          padding: 10px;
          margin-top: 10px;
          border-radius: 15px;

          .counter {
            margin-top: auto;
            margin-bottom: auto;
            font-size: 25px;
            font-weight: bold;
          }

          .q_text {
            margin-right: 15px;

          }

          .ant-radio-button-wrapper {
            text-align: start;
            font-size: large;
            height: auto;
            width: 100%;
            border-radius: 10px;
            font-size: initial;
          }

          .ant-radio-button-wrapper-checked {
            background-color: $blueDark;
            border-color: $blueDark;
          }
        }

       
      }
      
      .secondline {
        display: flex;
        width: 100%;
        height: 47px;
        border-bottom: 1px solid black;
        margin-top: -20px;
        margin-bottom: 15px;
      }
    }
  }
}
