@font-face {
  font-family: Vazir;
  src: url("../assets/fonts/eot/Vazir-Regular.eot");
  src: url("../assets/fonts/eot/Vazir-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/woff2/Vazir-Regular.woff2") format("woff2"),
    url("../assets/fonts/woff/Vazir-Regular.woff") format("woff"),
    url("../assets/fonts/ttf/Vazir-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url("../assets/fonts/eot/Vazir-Bold.eot");
  src: url("../assets/fonts/eot/Vazir-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/woff2/Vazir-Bold.woff2") format("woff2"),
    url("../assets/fonts/woff/Vazir-Bold.woff") format("woff"),
    url("../assets/fonts/ttf/Vazir-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url("../assets/fonts/eot/Vazir-Black.eot");
  src: url("../assets/fonts/eot/Vazir-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/woff2/Vazir-Black.woff2") format("woff2"),
    url("../assets/fonts/woff/Vazir-Black.woff") format("woff"),
    url("../assets/fonts/ttf/Vazir-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url("../assets/fonts/eot/Vazir-Medium.eot");
  src: url("../assets/fonts/eot/Vazir-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/woff2/Vazir-Medium.woff2") format("woff2"),
    url("../assets/fonts/woff/Vazir-Medium.woff") format("woff"),
    url("../assets/fonts/ttf/Vazir-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url("../assets/fonts/eot/Vazir-Light.eot");
  src: url("../assets/fonts/eot/Vazir-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/woff2/Vazir-Light.woff2") format("woff2"),
    url("../assets/fonts/woff/Vazir-Light.woff") format("woff"),
    url("../assets/fonts/ttf/Vazir-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url("../assets/fonts/eot/Vazir-Thin.eot");
  src: url("../assets/fonts/eot/Vazir-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/woff2/Vazir-Thin.woff2") format("woff2"),
    url("../assets/fonts/woff/Vazir-Thin.woff") format("woff"),
    url("../assets/fonts/ttf/Vazir-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Nazanin;
  src: url("../assets/fonts/ttf/Far_Nazanin.ttf") format("truetype");
}

@font-face {
  font-family: Roya;
  src: url("../assets/fonts/ttf/Far_Roya.ttf") format("truetype");
}

@font-face {
  font-family: Casablanca-Heavy;
  src: local("Far_CasablancaHeavy"),
    url("../assets/fonts/ttf/Far_CasablancaHeavy.ttf") format("truetype");
}

@font-face {
  font-family: Rezvan;
  src: url("../assets/fonts/ttf/Rezvan.ttf") format("truetype");
}

@font-face {
  font-family: Rezvan-fat;
  src: url("../assets/fonts/ttf/Rezvan-fat.ttf") format("truetype");
}

@font-face {
  font-family: yekan;
  src: url("../assets/fonts/ttf/Far_Yekan.ttf") format("truetype");
}

@font-face {
  font-family: Titr-Bold;
  src: url("../assets/fonts/ttf/B Titr Bold.ttf") format("truetype");
}
