.pagination-container {
    display: flex;
    list-style-type: none;
}
  
.pagination-container .pagination-item {
      padding: 0 12px;
      height: 32px;
      text-align: center;
      margin: auto 4px;
      color: var(--font-color);
      display: flex;
      box-sizing: border-box;
      align-items: center;
      letter-spacing: 0.01071em;
      border-radius: 16px;
      line-height: 1.43;
      font-size: 13px;
      min-width: 32px;
}
  
    
      .pagination-container .pagination-item:hover {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }
      .pagination-container .dots:hover {
              background-color: transparent;
              cursor: default;
            }
  
      .pagination-container .selected {
        background-color: rgba(0, 0, 0, 0.08);
      }
  
        .pagination-container .arrow::before {
          position: relative;
          /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
          content: '';
          /* By using an em scale, the arrows will size with the font */
          display: inline-block;
          width: 0.4em;
          height: 0.4em;
          border-right: 0.12em solid var(--font-color);
          border-top: 0.12em solid var(--font-color);
        }
  
        .pagination-container .arrow.left {
          transform: rotate(-135deg) translate(-50%);
        }
  
        .pagination-container .arrow.right {
          transform: rotate(45deg);
        }
  
        .pagination-container .disabled {
        pointer-events: none;
        }
  
        .pagination-container .disabled .arrow::before {
          border-right: 0.12em solid rgba(0, 0, 0, 0.43);
          border-top: 0.12em solid rgba(0, 0, 0, 0.43);
        }
  
        .pagination-container .pagination-item .dots.disabled:hover {
          background-color: transparent;
          cursor: default;
        }