@import "../../utilities/references/index";
.MBTI-INFO{
    .scroll {
           
        overflow-y:scroll;
    }

    .background{
        display: flex;
        width: 100%;
        height:max-content;
        position: absolute;
        text-align: justify;
        background: url(../../assets/images/2.jpg) center;
        background-size: cover;
        background-repeat:no-repeat;
        height: 100%;
        
        .content{
            display: flex;
            width: 100%;
            height:max-content;
            direction: rtl;
            margin-left: 5%;
            margin-right: 5%;
            margin-top: 25px;
            margin-bottom: 25px;
            flex-direction: column;
            background-color: $white;
            opacity: 90%;
            padding: 20px;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;    
            border-radius: 15px;

            .info{

                .infoText{
                    text-align: justify;
                    font-size: large;
                }

                h1{
                    text-align: center;
                }
            }
            
    
            .typescard1{
                display:flex;
                flex-direction: column;
                opacity: 90%;
                padding: 20px;
                flex-direction: column;
                text-align: justify;
                font-size: large;
                background-color : $LightGreen;
                border-radius: 15px;
                
                h2{
                    margin-top: 8px;
                    text-align: center;

                    @include project-md{
                        text-align: start;
                    }
                }
                
            }
            .typescard2{
                background-color:$LightPurple;
                display:flex;
                flex-direction: column;
                opacity: 90%;
                padding: 20px;
                flex-direction: column;
                text-align: justify;
                font-size: large;
                border-radius: 15px;

                h2{
                    margin-top: 8px;
                    text-align: center;

                    @include project-md{
                        text-align: start;
                    }
                }
            }
            .typescard3{
                background-color:$LightYellow;
                display:flex;
                flex-direction: column;
                opacity: 90%;
                padding: 20px;
                flex-direction: column;
                text-align: justify;
                font-size: large;
                border-radius: 15px;

                h2{
                    margin-top: 8px;
                    text-align: center;

                    @include project-md{
                        text-align: start;
                    }
                }
            }
            .typescard4{
                background-color:$LightBlue;
                display:flex;
                flex-direction: column;
                opacity: 90%;
                padding: 20px;
                flex-direction: column;
                text-align: justify;
                font-size: large;
                border-radius: 15px;

                h2{
                    margin-top: 8px;
                    text-align: center;

                    @include project-md{
                        text-align: start;
                    }
                }
            }

            .typescard5{    
                display: flex;
                position: relative;
                width: 100px;
                margin-left: auto;
                margin-right: auto;
                text-align: justify;
                font-size: large;
            }

            .info{
                text-align: justify;
                direction: rtl;
                padding-bottom: 5%;
    
            }
        
            .btn{
                position: relative;
                display: flex;

                .str{

                    .project-buttons{
                        padding: 15px;
                        background-color: $blueDark;
                        
                    }   
                }

                  
                
            }
        }
    }
 
}