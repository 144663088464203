@use 'sass:selector';

$white: #ffffff;
$black: #0e0d0d;
$grayLight: #afa7a7;
$gray: #302c2c;
$navyBlue:#0f2c38;
$blueDark:#144153;
$grayWhite:#ECECEC;
$blueDark:#1A5F7A;
$bluegreen: #086E7D;
$yellow:#FFC900;
$yellowLight:#FFF89A;
$red:#D9534F;
$DarkYellow:#775700;
$DarkPurple:#734e60;
$GreenDark:#22592e;
$purpleDark:#2f1f3a;
$purpleENTP:#caabd4;
$purpleINTJ:#8b5e94;
$purpleINTP:#d0b1c0;
$brownLight:#968a7a;
$blueESTJ:#9babbb;
$blueESFJ:#14222e;
$GreenINFP:#328c48;
$GreenINFJ:#a6ccbb;
$blackISFP:#6f6f6f;
$mustard:#bc9f35;
$darkPink:#56322b;
$blueESTP:#345c74;
$blueISFJ:#cde6ea;
$darkBlueISFJ:#72adb5;
$LightBlue:#72cccd;
$LightGreen:#909052;
$LightYellow:#e5c727;
$LightPurple:#b3889c;
$darkYellow:#836506;


 