@import "../../utilities/references/index";

// body{
//background-color: $white;

.background-startpage {
    display: flex;
    width: 100%;
    position: absolute;
    //align-items: center;
    text-align: justify;
    background: url(../../assets/images/2.jpg) center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    font-size: 130%;

    .content {
        display: flex;
        width: 100%;
        height: fit-content;
        
        margin-left: 5%;
        margin-right: 5%;
        margin-top: auto;
        margin-bottom: auto;

        padding: 20px;

        flex-direction: column;
        direction: rtl;

        background-color: $white;
        opacity: 90%;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
        border-radius: 15px;

        @include project-md {
            margin-left: 15%;
            margin-right: 15%;
            margin-top: auto;
            margin-bottom: auto;
        }

        .info {
            text-align: justify;
            direction: rtl;
            padding-bottom: 5%;

            p {
                text-align: right;
            }

            b {
                font-weight: 500;
            }
        }

        .btn {
            position: relative;
            display: flex;
            flex-direction: row-reverse;

            .str {

                .project-buttons {
                    padding: 15px;
                    background-color: $blueDark;
                }
            }

            .mbti {
                margin-left: 20px;

                .project-buttons {
                    padding: 15px;
                    background-color: $red;
                }
            }
        }
    }
}

// }